@font-face {
	font-family: 'Montserrat';
	src: url('/Montserrat-Regular.ttf') format('eot'), url('/Montserrat-Regular.woff') format('woff'), url('/Montserrat-Regular.ttf') format('truetype');
}

.containerSocialButton {
	display: flex;
	z-index: 999999;
	position: fixed;
	bottom: 28px;
	right: 28px;
}

.socialButton {
	width: 58px;
	height: 58px;

	cursor: pointer;
	border-radius: 50%;
	box-shadow: 0 0 0 0 rgba(#76ae6a, .5);
	animation: 2s ease infinite pulse;

	img {
		width: 100%;
		height: 100%;
	}
}

.socialPhoneButton {
	width: 58px;
	height: 58px;

	cursor: pointer;
	border-radius: 50%;
	box-shadow: 0 0 0 0 rgba(#e4d7d565, .5);
	animation: 2s ease infinite pulse;

	img {
		width: 100%;
		height: 100%;
	}
}

.socialButton:hover {
	animation: none;
}

@keyframes pulse {
	0% {
		transform: scale(0.9);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 40px rgba(#76ae6a, 0);
	}

	100% {
		transform: scale(0.9);
		box-shadow: 0 0 0 0 rgba(#76ae6a, 0);
	}
}

