// MARK: General
$white: #ffffff;
$white-startapp: #fafafa;
$light-gray: #d0d0d0;
$very-light-gray: #e8e8e8;
$gray: #818181;
$black: #000000;
$space-gray: #222222;
$dark-blue: rgb(16, 28, 48);

// MARK: Main
$primary-color: #0047ba;
$secondary-color: #ffb912;
$muted-color: #878787;
$start-blue: rgb(105, 118, 140);
$end-blue: rgb(16, 28, 48);

$balloon-color: #e2e2e2;

// MARK: Components
// Text
$primary-text-color: #111c30;
$secondary-text-color: #4a4a4a;
$dark-gray: #232323;
$muted-text-color: #707070;
$background-color: #efefef;

// Error
$error-color: #c0392b;

// Shadow
$shadow-color: #000000;

// Separator
$separator-color: rgba(0, 0, 0, 0.1);
