@import "./colors";
@import "./dimensions";
@import "~nprogress/nprogress.css";

#nprogress .bar {
	background: $primary-color !important;
}

#nprogress .spinner-icon {
	border-top-color: $primary-color !important;
	border-left-color: $primary-color !important;
}

#nprogress .peg {
	box-shadow: 0 0 10px $primary-color, 0 0 5px $primary-color !important;
}

* {
	font-family: -apple-system, BlinkMacSystemFont, 'Titillium', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
	font-display: auto;
	color: $muted-text-color;
	box-sizing: border-box;
}

#__next, body, html {
	margin: 0;
	padding: 0;
	background-color: $background-color;
	height: 100%;
}

html {
	font-size: 62.5%;
}

.zEWidget-launcher {
	@media (max-width: $tablet-width) {
		bottom: 60px !important;
    }
}
